const skillsData = [
    {
      category: "Base de données",
      color: "#4caf50", 
      skills: [
        { name: "MySQL", completion: 80 },
        { name: "PostgreSQL", completion: 75 },
        { name: "SQLite", completion: 70 },
        { name: "MongoDB", completion: 60 },
        { name: "Firebase Realtime Database", completion: 40 },
      ],
    },
    {
      category: "Langages et frameworks",
      color: "#2196f3", 
      skills: [
        { name: "HTML/CSS", completion: 90 },
        { name: "Javascript", completion: 85 },
        { name: "Typescript", completion: 60 },
        { name: "SQL", completion: 70 },
        { name: "Python", completion: 70 },
        { name: "PHP", completion: 80 },
        { name: "Java", completion: 75 },
        { name: "Spring Boot", completion: 70 },
        { name: "Angular", completion: 65 },
        { name: "React", completion: 70 },
        { name: "React Native", completion: 90 },
        { name: "Symfony", completion: 70 },
        { name: "Bootstrap", completion: 80 },
        { name: "MaterialUI", completion: 80 },
      ],
    },
    {
      category: "Versionning et Tests",
      color: "#ff9800", 
      skills: [
        { name: "Git", completion: 80 },
        { name: "SVN", completion: 60 },
        { name: "Selenium", completion: 80 },
        { name: "Junit", completion: 65 },
        { name: "PhpUnit", completion: 70 },
        { name: "Jest", completion: 50 },
        { name: "UiPath", completion: 80 },
      ],
    },
    {
      category: "Déploiement et Cloud",
      color: "#9c27b0",
      skills: [
        { name: "Wamp", completion: 85 },
        { name: "Apache", completion: 80 },
        { name: "Nginx", completion: 80 },
        { name: "Docker", completion: 70 },
        { name: "Portainer.io", completion: 75 },
        { name: "AWS EC2", completion: 80 },
        { name: "AWS S3", completion: 85 },
        { name: "AWS Lambda", completion: 85 },
        { name: "AWS Redshift serverless", completion: 60 },
        { name: "AWS API Gateway", completion: 65 },
        { name: "AWS Quicksight", completion: 100 },
        { name: "AWS Amplify", completion: 85 },
        { name: "Firebase Auth", completion: 80 },
        { name: "Firebase Storage", completion: 75 },
        { name: "Firebase Firestore", completion: 80 },
      ],
    },
  ];
  
  export default skillsData;
  